<!--
 * @Description: 政策配置看板
 * @Author: LXG
 * @Date: 2021-06-10
 * @Editors: LXG
 * @LastEditTime: 2021-06-15
-->
<template>
    <div class="policy-config-billboard">
        <div
            class="pad-tb-5 wrap-header"
            :style="{'background-image':`url(${require('@/assets/images/policyConfigBillboard/efficacy-stat.png')})`}"
        >
            <div class="def-container">
                <h1 class="title">政策配置看板</h1>
                <div class="pad-rl-2 overview">
                    <p>
                        政策数字化情况
                        <el-tooltip
                            class="item"
                            effect="light"
                            content="政策数字化配置、发布总览"
                            placement="bottom"
                        >
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </p>
                    <div class="pad-tb-4 overview-total">
                        <div class="overview-total-item">
                            <p class="total-value">{{overviewTotal.zcwj}}</p>
                            <p class="total-label">政策文件</p>
                        </div>
                        <div class="overview-total-item">
                            <p class="total-value">{{overviewTotal.zcxm}}</p>
                            <p class="total-label">政策项目</p>
                        </div>
                    </div>
                    <div class="overview_footer">
                        截止：2021年6月10日
                    </div>
                </div>
            </div>
        </div>
        <div class="def-container wrap-content">
            <ul class="tabbar">
                <li
                    v-for="(item,index) in tabInfo.list"
                    :key="index"
                    class="marg-rl-1 tab-item"
                    :class="{'tab--active':index===tabInfo.active}"
                    @click="changeTab(item,index)"
                >
                    {{item.label}}
                </li>
            </ul>
            <div v-show="tabInfo.active==0">
                <el-table
                    v-loading="listLoading"
                    class="list-table"
                    :data="listData"
                    row-class-name="list-table-row"
                    @row-click="clickListRow"
                >
                    <el-table-column fixed="left" type="index" label="序号" width="55px" align="center">
                        <template slot-scope="scope">
                            {{(pageInfo.oldnum-1)*pageInfo.size+scope.$index+1}}
                        </template>
                    </el-table-column>
                    <el-table-column fixed="left" prop="NAME" label="政策项目" min-width="200px" align="center">
                    </el-table-column>
                    <el-table-column prop="jbxx" label="基本信息" min-width="50px" align="center">
                        <template slot-scope="scope">
                            <i v-if="scope.row.jbxx==1" class="el-icon-check" :style="{'color':variableStyle['color-green']}"></i>
                            <i v-else class="el-icon-close" :style="{'color':variableStyle['color-red']}"></i>
                        </template>
                    </el-table-column>
                    <el-table-column prop="czgc" label="操作规程" min-width="50px" align="center">
                        <template slot-scope="scope">
                            <i v-if="scope.row.FLOW_ID==1" class="el-icon-check"  :style="{'color':variableStyle['color-green']}"></i>
                            <i v-else class="el-icon-close" :style="{'color':variableStyle['color-red']}"></i>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pzcl" label="配置材料" min-width="50px" align="center"></el-table-column>
                    <el-table-column prop="dzfb" label="订制范本" min-width="50px" align="center"></el-table-column>
                    <el-table-column prop="zzqx" label="资助标准" min-width="50px" align="center"></el-table-column>
                    <el-table-column prop="FORMID" label="配置表单" min-width="50px" align="center">
                        <template slot-scope="scope">
                            <i v-if="scope.row.FORMID==1" class="el-icon-check" :style="{'color':variableStyle['color-green']}"></i>
                            <i v-else class="el-icon-close" :style="{'color':variableStyle['color-red']}"></i>
                        </template>
                    </el-table-column>
                    <el-table-column prop="runflowimg" label="配置流程" min-width="50px" align="center">
                        <template slot-scope="scope">
                            <i v-if="scope.row.runflowimg==1" class="el-icon-check" :style="{'color':variableStyle['color-green']}"></i>
                            <i v-else class="el-icon-close" :style="{'color':variableStyle['color-red']}"></i>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sxmc" label="外网申报" min-width="50px" align="center"></el-table-column>
                    <el-table-column prop="sxmcbj" label="审批办理" min-width="50px" align="center"></el-table-column>
                    <el-table-column prop="release_status" label="发布状态" min-width="50px" align="center">
                        <template slot-scope="scope">
                            <i v-if="scope.row.release_status==1" class="el-icon-check" :style="{'color':variableStyle['color-green']}" ></i>
                            <i v-else class="el-icon-close" :style="{'color':variableStyle['color-red']}"></i>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <div class="pagination-elevator">
                        <FormInput
                            v-model.number="pageInfo.num"
                            size="small"
                            placeholder=""
                        ></FormInput>
                        <div>/ {{cpu_totalPage}}</div>
                        <div
                            class="pad-rl-1 elevator-btn"
                            @click="changeElevator"
                        >跳转</div>
                    </div>
                    <div
                        class="pagination-back"
                        @click="changePage(-1)"
                    >&lt;</div>
                    <div
                        class="pagination-next"
                        @click="changePage(1)"
                    >></div>
                </div>
                <p>
                    <span>说明：</span>
                    统计数据只包含政策项目未发布。
                </p>
            </div>
            <div v-show="tabInfo.active==1">
                <div class="marg-tb-2 empty">
                    <img :src="require('@/assets/images/policyConfigBillboard/matters-empty.png')" />
                    <p>没有找到数据</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FormInput } from '@/components/form/index.js'
import variableStyle from '@/styles/variable.scss'

export default {
    name: 'PolicyConfigBillboard',
    components: {
        FormInput,
    },
    data() {
        return {
            variableStyle: variableStyle,
            tabInfo: {
                active: 0,
                list: [
                    { value: '0', label: '政策配置情况' },
                   // { value: '1', label: '空页面示例' },
                ],
            },
            overviewTotal: {
                zcwj: 0, // 政策文件
                zcxm: 0, // 政策项目
            },
            listData: [],
            listLoading: false,
            pageInfo: {
                num: 1,
                oldnum: 1,
                size: 10,
                total: 10,
            },
        }
    },
    computed: {
        cpu_totalPage() {
            return Math.ceil(this.pageInfo.total / this.pageInfo.size)
        },
    },
    methods: {
        /**
         * @description: 切换tab
         * @param {Object} item tab
         * @param {Number} index 下标
         */
        changeTab(item, index) {
            this.tabInfo.active = index
        },
        /**
         * @description: 点击表格行
         * @param {Object} row 行
         * @param {Object} column 列
         */
        clickListRow(row, column) {
            this.$router.push({
                name: 'policyMatterPresence',
            })
        },
        /**
         * @description 分页电梯
         */
        changeElevator() {
            let num = parseInt(this.pageInfo.num)
            if (isNaN(num) || num < 1 || this.cpu_totalPage < num) {
                this.pageInfo.num = this.pageInfo.oldnum
                return
            }
            this.pageInfo.oldnum = this.pageInfo.num
            this.getListData()
        },
        /**
         * @description 修改页码
         */
        changePage(num) {
            if (num < 0 && this.pageInfo.oldnum === 1) {
                return
            }
            if (0 < num && this.pageInfo.oldnum === this.cpu_totalPage) {
                return
            }
            this.pageInfo.oldnum = this.pageInfo.oldnum + num
            this.pageInfo.num = this.pageInfo.oldnum
            this.getListData()
        },
        /**
         * @description 查询面板统计数目
         */
        getOverviewTotal() {
            let url = '/dev-api/basicinfo/baseitem/infoAllocation'
            let params = {}

            this.$httpApi.get(url, params).then(res => {
                console.log('getListData:', res)
                this.overviewTotal.zcwj = res.zcwj || 0
                this.overviewTotal.zcxm = res.zcxm || 0
            }).catch(err => {
                console.log('getListData error:', err)
            })
        },
        /**
         * @description 查询列表数据
         * @param {Number} pageNum 页码
         * @param {Number} pageSize 单页记录数
         */
        getListData(pageNum, pageSize) {
            this.pageInfo.oldnum = pageNum ?? this.pageInfo.oldnum
            this.pageInfo.num = pageNum ?? this.pageInfo.oldnum
            this.pageInfo.size = pageSize ?? this.pageInfo.size

            let url = '/dev-api/basicinfo/baseitem/infoAllocationlist'
            let params = {
                pageNum: this.pageInfo.oldnum,
                pageSize: this.pageInfo.size,
            }
            this.listLoading = true
            this.$httpApi.get(url, params).then(res => {
                console.log('getListData:', res)
                this.listLoading = false
                if (res.code == 200) {
                    this.listData = res.rows || []
                    this.pageInfo.total = res.total
                }
            }).catch(err => {
                console.log('getListData error:', err)
                this.listLoading = false
            })
        },
    },
    created() {
        this.getOverviewTotal()
        this.getListData()
    },
}
</script>

<style lang="scss" scoped>
.wrap-header {
    margin-bottom: 40px;
    background-color: #09294d;
    background-position: center bottom;
    background-repeat: no-repeat;
}
.title {
    margin-bottom: 40px;
    line-height: 1;
    font-size: 2rem;
    color: #ffffff;
}
.overview {
    width: 18rem;
    padding-top: 10px;
    color: #ffffff;
    background-color: #233e5f;

    .overview-total {
        display: -webkit-flex;
        display: flex;
    }
    .overview-total-item {
        flex: 1;
        text-align: center;

        .total-value {
            font-size: 2rem;
        }
        .total-label {
            color: #9ea9b6;
        }
    }
    .overview_footer {
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid #38506f;
        text-align: center;
        color: #9ea9b6;
    }
}
.wrap-content {
    margin-bottom: 40px;
}
.tabbar {
    border-bottom: 4px solid #000000;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: 700;
    color: #000000;

    .tab-item {
        position: relative;
        display: inline-block;
        line-height: 2.5;
        cursor: pointer;

        &:hover {
            color: #4293f4;
        }

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

        &.tab--active {
            color: #4293f4;

            &::after {
                content: "";
                position: absolute;
                bottom: -4px;
                left: 0;
                display: block;
                width: 100%;
                height: 8px;
                background-color: #4293f4;
            }
        }
    }
}
::v-deep .list-table {
    margin-bottom: 20px;

    .el-table__header th {
        font-weight: normal;
    }
    .el-table__body td {
        color: #000000;
    }

    .list-table-row {
        cursor: pointer;
    }
}
.pagination {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    line-height: 1;

    .pagination-elevator {
        display: -webkit-flex;
        display: flex;
        align-items: center;

        ::v-deep .form-input {
            width: 50px;
            margin-right: 10px;

            .el-input__inner {
                text-align: center;
            }
        }

        .elevator-btn {
            display: inline-block;
            height: 32px;
            margin-left: 10px;
            line-height: 32px;
            text-align: center;
            color: #4293f4;
            background-color: rgba(#4293f4, 0.2);
            cursor: pointer;

            &:hover {
                color: #ffffff;
                background-color: rgba(#4293f4, 0.8);
            }
        }
    }
    .pagination-back,
    .pagination-next {
        width: 32px;
        height: 32px;
        margin-left: 10px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        background-color: #4293f4;
        cursor: pointer;

        &:hover {
            background-color: rgba(#4293f4, 0.8);
        }
    }
}
.empty {
    line-height: 2;
    text-align: center;

    img {
        height: 10rem;
    }
}
</style>